import NflPickemLogo from "../components/icons/NflPickem"
import { gridBreakPoints, fontFamily, fontWeight, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import React from "react"
import styled from "styled-components/macro"
import constants from "../../common/constants"
const { breakpoints } = gridBreakPoints

const bkg = `url(${constants.DOMAIN}/static/sport-assets/basketball/default-bg-light.png)`

const UnderConstructionWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  background-image: ${bkg};
  background-size: cover;

  & > .logo {
    width: 10rem;
    height: 10rem;
    margin: 5.5rem auto 0;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  & > .title {
    display: block;
    font-family: ${fontFamily.base};
    font-weight: ${fontWeight.black};
    letter-spacing: 0em;
    text-align: center;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.5;
  }

  & > .sub-title {
    display: block;
    font-family: ${fontFamily.base};
    font-style: normal;
    font-weight: ${fontWeight.bold};
    letter-spacing: 0em;
    text-align: center;
    margin-top: 1rem;
    line-height: 1;
    font-size: 0.75rem;
    padding-bottom: 3rem;
  }
  // force build

  @media (min-width: ${pxToRem(breakpoints.sm)}) {
    padding: 6rem;
    padding-top: 0;
    & > .logo {
      width: 23rem;
      height: 23rem;
      margin: 7.5rem auto 0;
    }

    & > .title {
      font-size: 43px;
      line-height: 45px;
    }

    & > .sub-title {
      margin-top: 2.25rem;
      line-height: 33px;
      font-size: 32px;
      padding-bottom: 0;
    }
  }
`

const UnderConstruction = () => (
  <UnderConstructionWrap>
    <div className="logo">
      <NflPickemLogo />
    </div>
    <span className="title">Football Pick’em Games Under Construction</span>
    <span className="sub-title">
      We are currently working on enhancing your experience for the 2024 Football Pick’em Season. Check back in the summer for our scheduled games
      launch.
    </span>
  </UnderConstructionWrap>
)
export default UnderConstruction
